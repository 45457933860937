@import "../../styles";

.form {
    padding: 20px;
    border-radius: 20px;
    background: white;
    width: 30%;
    display: grid;
    gap: 16px;
    grid-template-rows: 1fr max-content max-content max-content;
    position: absolute;
    z-index: 1;

    .errorMessage {
        font-size: 16px;
        color: $tertiary;
    }

    h1 {
        font-size: 20px;
    }

    input {
        flex: 1;
        font-size: 14px;
        border: none;
        outline: none;
        border-bottom: 1px solid $disabled;
    }

    button {
        cursor: pointer;
        background-color: $primary;
        border: none;
        padding: 10px;
        font-weight: bold;
        font-size: 14px;
        border-radius: 10px;
        width: 100px;
        margin: 0 auto;
        color: black;
    }

    .forgotPasswordText {
        font-size: 14px;
        color: $tertiary;
        text-align: center;
    }

    .forgotPasswordForm {
        margin-top: 20px;
        display: grid;
        gap: 16px;
        grid-template-rows: max-content max-content;
    }
}
