$primary: #f5e500;
$secondary: #626a6d;
$tertiary: #8d9194;
$disabled: #a0a3a4;
$grey-light: #EFF1F999;
$light: #ffff;
$background: #333229;
$hover: #a0a3a4;
$warning: #ffc43b;
$info: #81bff9;
$danger: #E84855;
$success: #86d44b;
$input-font: #5E6366;

.button{
    padding: 10px 15px;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
        cursor: not-allowed;
        background-color: $disabled;
    }

}

.header {
    display: grid;
    grid-template-columns: max-content max-content 1fr max-content;
    gap: 8px;

    p {
        height: max-content;
        align-self: end;
        margin-bottom: 4px;
        color: $secondary;
    }

    input {
        outline: none;
        width: auto;
        font-size: 1em;
        padding: 0;
        padding-left: 8px;
        border: 1px solid $disabled;
        border-radius: 8px;
    }

    button {
        outline: none;
        background: $primary;
        border: none;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 8px;
        font-weight: 600;
        letter-spacing: 0.5px;
    }
}


.fieldContainer {
    position: relative;
    width: 100%;
    height: 60px;
    padding: 5px;
    border: none;
    border-radius: 10px;
    background-color: $grey-light;
    margin-bottom: 24px;
}


.fieldLabel {
    position: absolute;
    top: 0px;
    left: 5px;
    font-size: 11px;
    color: $input-font;
    padding: 8px 5px;
    margin-bottom: 5px;
    font-weight: bold;
}

.fieldContent {
    padding: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500; 
    border: none;
    outline: none;
    width: calc(100% - 20px);
    height: 100%;
    overflow: scroll;
    background-color: transparent;
    top: 8px;
    position: relative;
}

.fieldsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
}

.fieldColumn {
    flex: 1 1 25%; /* Cada columna ocupa 1/3 del contenedor */
}


.fieldContainer.big{
    height: 163px;
}

/* Ocultar scrollbars en WebKit (Chrome, Safari, Edge) */
.fieldContent.autocomplete::-webkit-scrollbar {
    display: none;
}

.fieldContent.autocomplete{
    top: 39%;
    position: relative;
    padding: 0px 18px;
    scrollbar-width: none; 
}

.footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  
    button {
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        border-radius: 8px;
        font-weight: bold;
    }
  
    .cancel {
        background: lightgray;
    }
  
    .save {
        background: $primary;
    }
}

