@import '../styles.scss';
// $colors: #F1C40F, #F39C12, #2ECC71, #E74C3C, #3498DB;
$colors: $primary $secondary $tertiary;
.login {
    width: 100%;
    height: 100vh;
    // background: $disabled;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .blurred {
        backdrop-filter: blur(60px);
        width: 100%;
        height: 100%;
        position: absolute;
        // top: 50%;
    }

    @for $i from 1 to 6 {
        $rotation: random(360);
        $speed: random(10) + 10;
        $leftoffset: random(500) + 10;
        $color-key: random( length($colors) );
        $color: nth( $colors, $color-key );
        $width: random(900) + 50;
        $blobwidth: random(1200) + 800;

        @keyframes rotation-#{$i} {
          0% {
            transform: translate3d(0,0,0) rotate(#{$rotation}deg);
          }
          100% {
            transform: translate3d(0,0,0) rotate(#{$rotation + 360}deg);
          }
        }

        .blob-wrapper-#{$i} {
            width: #{$width}px;
            height: 40px;
            animation: rotation-#{$i} #{$speed}s linear infinite;
            position: relative;
            z-index: -1;

            .blob {
                width: #{$blobwidth}px;
                height: #{$blobwidth}px;
                // height: 400px;
                background-color: $color;
                border-radius: 50%;
                // position: absolute;
                // top: 0;
                // left: #{$leftoffset}px;
            }
        }

    }
}
